<template>
  <div class="header">
    <div class="contont">
      <div class="left">
        <img class="img" src="@/assets/home/logo.png" alt="" @click="toHome">
        <!-- <div :class="[Highlight === 0 ? 'Highlight' : '', 'text']" @click="procurementHandle(0)">轻松选购</div> -->
        <!-- <div :class="[Highlight === 1 ? 'Highlight' : '', 'text']" @click="procurementHandle(1)">高校采购</div> -->
      </div>
      <div class="right">
        <el-input
          v-model="inputSearch"
          :prefix-icon="Search"
          style="max-width: 320px; margin-top: 0px;margin-right: 30px;"
          placeholder="请输入商品"
          @change="searchHandle('change')"
        >
          <template #append>
            <div @click="searchHandle('click')" style="width:68px;text-align:center;">搜索</div>
          </template>
        </el-input>
        <el-text class="text" v-if="!userInfo.avatar" @click="loginHandle">登录</el-text>
        <el-avatar v-if="userInfo.avatar" :src="userInfo.avatar" />
        <el-text v-if="userInfo.avatar" class="text" @click="handleCommand('b')">个人中心</el-text>
        <el-text v-if="userInfo.avatar" class="text" style="color:#c5cbc9;font-size:14px;" @click="handleCommand('a')">退出</el-text>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { logout } from '@/api/public';
import { Search } from '@element-plus/icons-vue';
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
import { useStore } from 'vuex';
const store = useStore();

// 用户信息
const userInfo = computed(() => store.state.userInfo || JSON.parse(sessionStorage.getItem('userInfo')));;
// 下拉事件
const handleCommand = (val) => {
  if (val == 'a') {
    logout().then(res => {
      if (res.code == 200) {
        store.commit('tokenF', '');
        sessionStorage.setItem('token', '');
        window.location.href = window.location.origin;
        // router.go(0);
      };
    });
  };
  if (val == 'b') {
    router.push({
      path:'/user'
    })
  };
};
//去主页
const toHome = () =>{
  router.push({
    path:'/home'
  })
}

// 轻松采购 or 高校采购
const Highlight = ref(0);
const procurementHandle = (val) => {
  Highlight.value = val;
};

// 登录显示
const loginHandle = () => {
  store.commit('isShowLoginF', true)
}

// 搜索
const inputSearch = ref('');
const searchHandle = (val) => {
  if (val == 'click') {
    window.open(window.location.origin + '/#/search?inputSearch=' + inputSearch.value)
  }
  if (val == 'change' && inputSearch.value) {
    window.open(window.location.origin + '/#/search?inputSearch=' + inputSearch.value)
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 76px;
  background: rgba(255,255,255,0.80);
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px #d6d4d4;
  .contont {
    width: 1200px;
    height: 76px;
    line-height: 76px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .left {
      height: 76px;
      display: flex;
      .img {
        width: 140px;
        height: 52px;
        cursor: pointer;
        margin-top: 12px;
      }
      .text {
        margin: 0px 32px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0;
        font-weight: 400;
        cursor: pointer;
      }
      .Highlight {
        font-family: PingFangSC-Semibold;
        color: #02A87B;
        font-weight: 600;
      }
    }
    .right {
      width: 800px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: -12px;
      ::v-deep .el-input .el-input__inner {
        height: 40px;
        line-height: 40px;
      }
      ::v-deep .el-input-group__append, .el-input-group__prepend {
        background-image: linear-gradient(137deg, #01B04E 0%, #04A0A4 100%);
        box-shadow: 0 1px 0 0 #01B04E inset,0 -1px 0 0 #01B04E inset,-1px 0 0 0 #01B04E inset;
        color: #fff;
        cursor: pointer;
        padding: 0px;
        width: 68px;
      }
      .text {
        color: #02A87B;
        font-size: 16px;
        cursor: pointer;
        margin-left: 30px;
      }
    }
  }
}
</style>