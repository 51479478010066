<template>
  <div class="right">
    <el-tooltip placement="left"  effect="light">
      <template #content>
        <div style="text-align: center;position: relative;">
          <img style="width:300px;height:500px;margin:auto;" src="@/assets/right/weixin.png" alt="">
          <p style="font-size:12px;position:absolute;left:80px;bottom:10px;">客服电话：18611759228</p>
        </div>
      </template>
      <div :class="['contact', isTop ? '' : 'borr']">
        <img src="@/assets/right/contact.png" alt="">
      </div>
    </el-tooltip>
    <div class="toTop" v-show="isTop" @click="backToTop">
      <div><el-icon><CaretTop /></el-icon></div>
      TOP
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
// 判断是否显示返回顶部
let isTop =ref(false)
window.addEventListener('scroll', () => {
  if (window.pageYOffset > 300) {
    isTop.value = true
  }
  if (window.pageYOffset < 300) {
    isTop.value = false
  }
});
let backToTop = () => {
  document.documentElement.scrollTop = document.body.scrollTop = 0;
}
</script>

<style lang="scss" scoped>
.right {
  position: fixed;
  right: 0px;
  bottom: 100px;
  .contact {
    width: 48px;
    height: 48px;
    border-radius: 8px 8px 0px 0px;
    background: #ffffff;
    padding: 8px;
    border: 1px solid #e7e7e7;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .borr {
    border-radius: 8px;
  }
  .toTop {
    width: 48px;
    border-radius: 0px 0px 8px 8px;
    background: #ffffff;
    padding: 8px;
    border: 1px solid #e7e7e7;
    cursor: pointer;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #7D7D7D;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
  }
}
</style>